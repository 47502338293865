<template>
  <div>
    <!-- 1. option: with toolbar -->
    <base-dialog-toolbar
      v-if="toolbar"
      :icon-name="iconName"
      dense
      style="margin-top: 10px;"
    >
      <slot>
        {{ title }}
      </slot>
    </base-dialog-toolbar>

    <!-- 2. option: without toolbar -->
    <span v-else :class="titleClass">
      <v-icon :color="iconColorClass" v-if="iconName">{{ iconName }}</v-icon>
      <slot>{{ title }}</slot>
      <v-divider></v-divider>
    </span>

    <slot name="field"></slot>
  </div>
</template>

<script>
import { dialogHeaderMixin } from "@/mixins/shared/base/dialog/dialogHeaderMixin";
export default {
  name: "LayoutEditFieldDialog",
  mixins: [dialogHeaderMixin],
  components: {
    BaseDialogToolbar: () =>
      import("@/components/shared/base/BaseDialogToolbar")
  },
  props: {
    /**
     * Use Base Dialog Toolbar instead base-card-header
     */
    toolbar: {
      type: Boolean,
      default: false
    }
  }
};
</script>
